.login-page {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;

    &__inner {
        width: 400px;
        max-width: 100vw;
    }
}
