$sortActiveColor: #1677ff;
.bank-clients-table {
    th {
        cursor: pointer;
        user-select: none;
        &:hover {
            .bank-clients-table__sort {
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }
    &__check-order {
        margin-left: 8px;
    }
    &__sort {
        line-height: 0;
        font-size: 12px;
        color: rgba(0, 0, 0, .29);
        transition: all 0.3s;
        &--asc {
            .bank-clients-table__sort-arrow {
                &:first-child {
                    color: $sortActiveColor;
                }
            }
        }
        &--desc {
            .bank-clients-table__sort-arrow {
                &:last-child {
                    color: $sortActiveColor;
                }
            }
        }
    }
    &__sort-arrow {
        &:last-child {
            margin-top: -0.3em;
        }
    }
}